var axios = require("axios").default;
const baseUrl = `https://api.stage.ebsr.in/`;
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localData.token
import {api} from '@/config/config'
export default class PaymentReceivable {
    async ViewAllPayment() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}paymentReceivable/viewAllPayment`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async PaymentReceivableDetail(payment_id){
        try {
            const response = await axios.request({ 
                method: "POST",
                url: `${baseUrl}paymentReceivable/viewPaymentInfoByPaymentId`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    "payment_id" : payment_id
                    }
            })

            
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async PaymentInfoDelete(payment_info_id, payment_id){
        try {
            const response = await axios.request({ 
                method: "POST",
                url: `${baseUrl}paymentReceivable/deletePaymentInfoById`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    "payment_info_id" : payment_info_id
                    }
            })

            
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    }
    
}