<template>
    <v-card>
        <!-- <v-card-title class="justify-center">
          <span class="text-h5"><strong> Payment Receivable</strong></span>
      </v-card-title> -->
        <v-card-title class="justify-space-between mb-0 py-7">
            <span class="text-h5"><strong>Payment Receivable</strong></span>
        </v-card-title>
        <v-row class="ml-2 mr-2 mb-3">
            <v-col cols="12" md="3">
                <v-text-field v-model="filter.hospital_name" label="Hospital Name" outlined dense hide-details>
                </v-text-field>
            </v-col>
            <v-col cols="12" md="3">
                <v-text-field v-model="filter.payment_status" label="Payment Status" outlined dense hide-details>
                </v-text-field>
            </v-col>

            <v-col cols="12" md="3">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="filter.date_from"
                    transition="scale-transition" offset-y min-width="auto" hide-details>
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateFormateFrom" label="Start Date : DD-MM-YYYY" readonly outlined dense hide-details
                            v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.date_from" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.menu.save('')">Clear </v-btn>
                        <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(filter.date_from)"> OK </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="12" md="3">
                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="filter.date_to"
                    transition="scale-transition" offset-y min-width="auto" hide-details>
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateFormateTo" label="End Date : DD-MM-YYYY" readonly outlined dense hide-details
                            v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.date_to" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.menu1.save('')">Clear </v-btn>
                        <v-btn text color="primary" @click="menu1 = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.menu1.save(filter.date_to)"> OK </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-col>

            <v-col cols="6" md="6">
                <v-btn color="primary" class="mr-2" @click="PaymentReceivableSearchList()"> Search</v-btn>
                <v-btn color="primary" @click="getAllPayment()">Reset</v-btn>
            </v-col>
        </v-row>

        <v-data-table :headers="headers" :items="GetLadingBill" :items-per-page="10" fixed-header height="400"
            item-key='invoice_no' :single-expand="singleExpand" :expanded.sync="expanded" class="table-rounded">

            <template #[`item.invoice_date`]="{ item }">
                <pre>{{ moment.utc(item.invoice_date).format("DD-MM-YYYY") }}</pre>
            </template>

            <template #[`item.operate`]="{ item }">
                <v-btn color="primary"
                    v-if="item.payment_status == 'Unpaid' || item.payment_status == 'Partially pending' || item.payment_status == 'unpaid' || item.payment_status == 'partially pending'" class="ml-5"
                    @click="receivePaymentView(item)" target="_blank"> Receive Payment
                </v-btn>
                <v-btn color="primary" v-if="item.payment_status == 'Paid'" class="ml-5"
                    @click="receivePaymentView(item)" target="_blank"> View Payment
                </v-btn>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-row class="pt-3 col-12">
                        <v-col sm="6" md="6">
                            <p><strong> Surgery information </strong></p>
                            <p>Bill To: {{ item.bill_to }}</p>
                            <p>Surgery Date-Time: {{ item.surgery_date_time }}</p>
                            <p>Reimbursement Amount: {{ item.reimbursement_amount }}</p>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>



        <v-dialog v-model="viewReceivePaymentModal" max-width="1400">
            <v-card>
                <v-card-title class="justify-center">
                    <span class="text-h5"><strong>Invoice Detail</strong></span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="3">
                                <strong> Invoice No. :</strong> {{ this.PaymentRecevableList.invoice_no }}
                            </v-col>
                            <v-col cols="3">
                                <strong> Invoice Date :</strong>
                                {{ moment(this.PaymentRecevableList.invoice_date).format("DD-MM-YYYY") }}
                            </v-col>
                            <v-col cols="3">
                                <strong> Payment Status :</strong> {{ this.PaymentRecevableList.payment_status }}
                            </v-col>
                            <v-col cols="3">
                                <strong> Hospital Name :</strong> {{ this.PaymentRecevableList.hospital_name }}
                            </v-col>
                            <v-col cols="3">
                                <strong> Total Amount :</strong> {{ this.PaymentRecevableList.bill_amount }}
                            </v-col>
                            <v-col cols="3">
                                <strong> Received Amount :</strong> {{ this.PaymentRecevableList.received_amount }}
                            </v-col>
                            <v-col cols="3">
                                <strong> Outstanding Amount :</strong> {{
                                    this.PaymentRecevableList.bill_amount -
                                        this.PaymentRecevableList.received_amount
                                }}
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-title class="justify-center">
                    <span class="text-h5"><strong>Add New Payment</strong></span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <!-- <v-col cols="3">
                              <v-menu ref="invoicemenu" v-model="invoicemenu" :close-on-content-click="false"
                                  :return-value.sync="PaymentRecevableList.invoice_date" transition="scale-transition"
                                  offset-y>
                                  <template v-slot:activator="{ on, attrs }">
                                      <v-text-field v-model="invoiceDateFormat" label="Invoice Date : DD-MM-YYYY*"
                                          readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
                                  </template>
                                  <v-date-picker v-model="PaymentRecevableList.invoice_date" no-title scrollable>
                                      <v-spacer></v-spacer>
                                      <v-btn text color="primary" @click="$refs.invoicemenu.save('')">Clear </v-btn>
                                      <v-btn text color="primary" @click="invoicemenu = false"> Cancel </v-btn>
                                      <v-btn text color="primary"
                                          @click="$refs.invoicemenu.save(PaymentRecevableList.invoice_date)"> OK
                                      </v-btn>
                                  </v-date-picker>
                              </v-menu>
                          </v-col> -->
                            <v-col cols="3">
                                <v-menu ref="datemenu" v-model="datemenu" :close-on-content-click="false"
                                    :return-value.sync="addPayment_info.paymentDate" transition="scale-transition"
                                    offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="paymentDateFormat" label="Payment Date : DD-MM-YYYY*"
                                            readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="addPayment_info.paymentDate" no-title scrollable
                                        :allowed-dates="(val) => val <= new Date().toISOString().substr(0, 10)">
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="$refs.datemenu.save('')">Clear </v-btn>
                                        <v-btn text color="primary" @click="datemenu = false"> Cancel </v-btn>
                                        <v-btn text color="primary"
                                            @click="$refs.datemenu.save(addPayment_info.paymentDate)"> OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="3" md="3">
                                <v-text-field v-model="addPayment_info.payAmount" outlined dense hide-details
                                    label="Payment Amount*" @keypress="onlyNumber" required @paste.prevent></v-text-field>
                            </v-col>
                            <v-col cols="3" md="3">
                                <v-select :items="items" v-model="addPayment_info.paymentMode" label="Payment Mode*"
                                    outlined dense hide-details @change="paymentModeChange()"></v-select>
                            </v-col>
                            <v-col cols="3" md="3" v-if="addPayment_info.paymentMode == 'UPI'">
                                <v-text-field v-model="addPayment_info.upiTransactionNo" outlined dense hide-details
                                    label="UPI Transaction No.*" required></v-text-field>
                            </v-col>
                            <v-col cols="3" md="3" v-if="addPayment_info.paymentMode == 'DD'">
                                <v-text-field v-model="addPayment_info.bankName" outlined dense hide-details
                                    label="Bank Name*" required></v-text-field>
                            </v-col>
                            <v-col cols="3" md="3" v-if="addPayment_info.paymentMode == 'DD'">
                                <v-text-field v-model="addPayment_info.branchName" outlined dense hide-details
                                    label="Branch Name*" required></v-text-field>
                            </v-col>
                            <v-col cols="3" md="3" v-if="addPayment_info.paymentMode == 'DD'">
                                <v-text-field v-model="addPayment_info.checkDdNo" outlined dense hide-details
                                    label="Check/DD No.*" required></v-text-field>
                            </v-col>
                            <v-col cols="3" md="3" v-if="addPayment_info.paymentMode == 'NEFT'">
                                <v-text-field v-model="addPayment_info.neftTransactionNo" outlined dense hide-details
                                    label="NEFT Transaction No.*" required></v-text-field>
                            </v-col>
                            <v-col cols="3" md="3">
                                <v-btn color="primary" :disabled="disableBtn" class="ml-0" @click="addNewPayment()"> Add New Payment </v-btn>
                            </v-col>
                            <!-- <v-col cols="3" md="3">
                              <v-text-field v-model="PaymentRecevableList.hospital_name" outlined dense hide-details
                                  label="Hospital Name*" required></v-text-field>
                          </v-col> -->
                        </v-row>

                        <v-card-title class="justify-center">
                            <span class="text-h5"><strong>Payment Information</strong></span>
                        </v-card-title>

                        <v-data-table :headers="headersInfo" :items="GetPaymentDetail" :items-per-page="5" fixed-header
                            height="350" item-key='invoice_no' :single-expand="singleExpand" :expanded.sync="expanded"
                            class="table-rounded">

                            <template #[`item.payment_date`]="{ item }">
                                <pre>{{ moment.utc(item.payment_date).format("DD-MM-YYYY") }}</pre>
                            </template>
                            <template #[`item.payment_detail`]="{ item }">
                                <p class="mb-1 mt-3" v-if="item.payment_mode == 'UPI'"><strong>Payment Id : </strong>{{item.payment_info_id}}</p>
                                <p v-if="item.payment_mode == 'UPI'"><strong>UPI Transaction No : </strong>{{item.upi_transaction_no}}</p>

                                <p class="mb-1 mt-3" v-if="item.payment_mode == 'DD'"><strong>Payment Id : </strong>{{item.payment_info_id}}</p>
                                <p class="mb-1" v-if="item.payment_mode == 'DD'"><strong>Bank Name : </strong>{{item.bank_name}}</p>
                                <p class="mb-1" v-if="item.payment_mode == 'DD'"><strong>Branch Name : </strong>{{item.branch_name}}</p>
                                <p v-if="item.payment_mode == 'DD'"><strong>Check/DD No : </strong>{{item.check_dd_no}}</p>
                                
                                <p class="mb-1 mt-3" v-if="item.payment_mode == 'NEFT'"><strong>Payment Id : </strong>{{item.payment_info_id}}</p>
                                <p v-if="item.payment_mode == 'NEFT'"><strong>NEFT Transaction No : </strong>{{item.neft_transaction_no}}</p>
                            </template>
                            <template #[`item.action`]="{ item }">
                                <v-btn color="primary" class="ml-0"
                                    @click="deletePaymentInfo(item.payment_info_id, item.payment_id)">
                                    Delete
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="paymentInfoModalClose()"> Close </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-snackbar v-model="snackbar" :timeout="timeout">
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
            </template>
        </v-snackbar>
    </v-card>
</template>

<script>

import { api } from '@/config/config'
import PaymentReceivable from '@/service/PaymentReceivable.service'
import moment from 'moment'
import { format, parseISO } from 'date-fns'
const localStoragedata = JSON.parse(localStorage.getItem("token"))
const token = localStoragedata.token
export default {


    data() {
        return {
            moment,
            DeleteModal: false,
            thisItemId: '',
            btn: false,
            btn1: false,
            expanded: [],
            singleExpand: true,
            hospital_name: '',
            Tissue_no: '',
            listLoading: false,
            totallist: 0,
            snackbar: false,
            menu: false,
            menu1: false,
            invoicemenu: false,
            datemenu: false,
            snackbarText: '',
            timeout: 2000,
            GetLadingBill: [],
            GetPaymentDetail: [],
            viewList: [],
            items: ['UPI', 'DD', 'NEFT'],
            viewReceivePaymentModal: false,
            PaymentRecevableList: {
                hospital_name: '',
                transaction_no: '',
                invoice_date: '',
                bill_amount: '',
                received_amount: '',
                invoice_no: '',
                payment_status: '',
                payment_id: '',
            },
            filter: {
                hospital_name: '',
                payment_status: '',
                date_from: '',
                date_to: ''
            },
            addPayment_info: {
                paymentDate: '',
                payAmount: '',
                paymentMode: '',
                upiTransactionNo: '',
                bankName: '',
                branchName: '',
                checkDdNo: '',
                neftTransactionNo: '',
            },
            headers: [
                { text: 'Invoice Date', value: 'invoice_date', width: '150px' },
                { text: 'Invoice Number', value: 'invoice_no', width: '150px' },
                { text: 'Hospital Name', value: 'hospital_name', width: '150px' },
                { text: 'Bill Amount', value: 'bill_amount', width: '140px' },
                { text: 'Received Amount', value: 'received_amount', width: '180px' },
                { text: 'Payment Status', value: 'payment_status', width: '150px' },
                { text: 'Receive Payment', value: 'operate', width: '180px' }
            ],

            headersInfo: [
                { text: 'Payment Date', value: 'payment_date', width: '150px' },
                { text: 'Payment Mode', value: 'payment_mode', width: '100px' },
                { text: 'Payment Details', value: 'payment_detail', width: '100px' },
                { text: 'Payment Amount', value: 'amount', width: '100px' },
                { text: 'Action', value: 'action', width: '150px' },
            ],
            disableBtn: false,
        }
    },
    watch: {
        options: {
            handler() {
                this.getAllPayment()
            },
            deep: true,
        },
    },

    computed: {
        dateFormateTo() {
            return this.filter.date_to ? moment(this.filter.date_to).format('DD-MM-YYYY') : ''
        },

        invoiceDateFormat() {
            return this.PaymentRecevableList.invoice_date ? moment(this.PaymentRecevableList.invoice_date).format('DD-MM-YYYY') : ''
        },

        paymentDateFormat() {
            return this.addPayment_info.paymentDate ? moment(this.addPayment_info.paymentDate).format('DD-MM-YYYY') : ''
        },

        dateFormateFrom() {
            return this.filter.date_from ? moment(this.filter.date_from).format('DD-MM-YYYY') : ''
        },
    },

    async mounted() {
        this.getAllPayment()
        this.autoSearchBillOFlading()
    },


    methods: {

        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault()
            }
        },

        paymentInfoModalClose() {
            this.viewReceivePaymentModal = false
            this.addPayment_info.paymentDate = '',
                this.addPayment_info.payAmount = '',
                this.addPayment_info.paymentMode = '',
                this.addPayment_info.upiTransactionNo = '',
                this.addPayment_info.bankName = '',
                this.addPayment_info.branchName = '',
                this.addPayment_info.checkDdNo = '',
                this.addPayment_info.neftTransactionNo = ''
        },

        async addNewPayment() {
            if (this.addPayment_info.paymentDate == "" || this.addPayment_info.paymentDate == "Invalid date" || this.addPayment_info.paymentDate === null) {
                this.snackbarText = "Please fill payment date."
                this.snackbar = true
                return false
            }
            else if (this.addPayment_info.payAmount == "" || this.addPayment_info.payAmount === null || this.addPayment_info.payAmount == '0') {
                this.snackbarText = "Please fill payment amount."
                this.snackbar = true
                return false
            }
            else if ((this.PaymentRecevableList.bill_amount - this.PaymentRecevableList.received_amount) < this.addPayment_info.payAmount) {
                this.snackbarText = "Payment amount can not be more than the total outstanding amount."
                this.snackbar = true
                return false
            }
            else if (this.addPayment_info.paymentMode == "" || this.addPayment_info.paymentMode === null) {
                this.snackbarText = "Please select payment mode."
                this.snackbar = true
                return false
            }
            else if (this.addPayment_info.paymentMode == "UPI" && this.addPayment_info.upiTransactionNo == "" || this.addPayment_info.upiTransactionNo === null) {
                this.snackbarText = "Please fill UPI transaction number."
                this.snackbar = true
                return false
            }
            else if (this.addPayment_info.paymentMode == "DD" && this.addPayment_info.bankName == "" || this.addPayment_info.bankName === null) {
                this.snackbarText = "Please fill bank name."
                this.snackbar = true
                return false
            }
            else if (this.addPayment_info.paymentMode == "DD" && this.addPayment_info.branchName == "" || this.addPayment_info.branchName === null) {
                this.snackbarText = "Please fill branch name."
                this.snackbar = true
                return false
            }
            else if (this.addPayment_info.paymentMode == "DD" && this.addPayment_info.checkDdNo == "" || this.addPayment_info.checkDdNo === null) {
                this.snackbarText = "Please fill cheque/dd number."
                this.snackbar = true
                return false
            }
            else if (this.addPayment_info.paymentMode == "NEFT" && this.addPayment_info.neftTransactionNo == "" || this.addPayment_info.neftTransactionNo === null) {
                this.snackbarText = "Please fill NEFT transaction number."
                this.snackbar = true
                return false
            }
            const data = {
                payment_id: this.PaymentRecevableList.payment_id,
                payment_mode: this.addPayment_info.paymentMode,
                payment_date: this.addPayment_info.paymentDate,
                upi_transaction_no: this.addPayment_info.upiTransactionNo,
                bank_name: this.addPayment_info.bankName,
                branch_name: this.addPayment_info.branchName,
                check_dd_no: this.addPayment_info.checkDdNo,
                neft_transaction_no: this.addPayment_info.neftTransactionNo,
                amount: this.addPayment_info.payAmount,
            }
            try {
                const response = await api.post(`paymentReceivable/addPaymentInfo`, data, {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                if (response.data.msg == "Payment Saved Successfully") {
                    this.addPayment_info.paymentMode = ''
                    this.addPayment_info.paymentDate = ''
                    this.addPayment_info.upiTransactionNo = ''
                    this.addPayment_info.bankName = ''
                    this.addPayment_info.branchName = ''
                    this.addPayment_info.checkDdNo = ''
                    this.addPayment_info.neftTransactionNo = ''
                    this.addPayment_info.payAmount = ''
                    const service = new PaymentReceivable()
                    let response1 = await service.PaymentReceivableDetail(this.PaymentRecevableList.payment_id)
                    if (response1) {
                        this.viewList = response1.data[0]
                        this.PaymentRecevableList.hospital_name = response1.data.hospital_name
                        this.PaymentRecevableList.invoice_date = response1.data.invoice_date
                        this.PaymentRecevableList.bill_amount = response1.data.bill_amount
                        this.PaymentRecevableList.received_amount = response1.data.received_amount,
                            this.PaymentRecevableList.invoice_no = response1.data.invoice_no
                        this.PaymentRecevableList.payment_status = response1.data.payment_status
                        this.PaymentRecevableList.payment_id = response1.data.payment_id
                        this.GetPaymentDetail = response1.data?.paymentInfo
                        if (this.PaymentRecevableList.payment_status == 'Paid') {
                            this.disableBtn = true
                        }
                        else {
                            this.disableBtn = false
                        }
                    } else {
                        this.viewList = []
                    }
                    this.getAllPayment()
                }
            } catch (e) {
                console.log(e)
            }
        },

        paymentModeChange() {
            let paymentMode = this.addPayment_info.payment_mode
            if (paymentMode == "UPI") {
                this.addPayment_info.bankName = ""
                this.addPayment_info.branchName = ""
                this.addPayment_info.checkDdNo = ""
                this.addPayment_info.neftTransactionNo = ""
            }
            if (paymentMode == "DD") {
                this.addPayment_info.upiTransactionNo = ""
                this.addPayment_info.neftTransactionNo = ""
            }
            else {
                this.addPayment_info.upiTransactionNo = ""
                this.addPayment_info.bankName = ""
                this.addPayment_info.branchName = ""
                this.addPayment_info.checkDdNo = ""
            }
        },

        async receivePaymentView(e) {
            this.itemDonorId = e.donor_id
            const service = new PaymentReceivable()
            let response = await service.PaymentReceivableDetail(e.payment_id)
            this.viewReceivePaymentModal = true
            if (response) {
                this.viewList = response.data[0]
                this.PaymentRecevableList.hospital_name = response.data.hospital_name
                this.PaymentRecevableList.invoice_date = response.data.invoice_date
                this.PaymentRecevableList.bill_amount = response.data.bill_amount
                this.PaymentRecevableList.received_amount = response.data.received_amount,
                    this.PaymentRecevableList.invoice_no = response.data.invoice_no
                this.PaymentRecevableList.payment_status = response.data.payment_status
                this.PaymentRecevableList.payment_id = response.data.payment_id
                this.GetPaymentDetail = response.data?.paymentInfo
                if (this.PaymentRecevableList.payment_status == 'Paid') {
                    this.disableBtn = true
                }
                else {
                    this.disableBtn = false
                }
            } else {
                this.viewList = []
            }
        },


        async deletePaymentInfo(payment_info_id, payment_id) {
            const service = new PaymentReceivable()
            let response = await service.PaymentInfoDelete(payment_info_id)
            if (response.message == "successfully Deleted") {
                let response1 = await service.PaymentReceivableDetail(payment_id)
                if (response1) {
                    this.viewList = response1.data[0]
                    this.PaymentRecevableList.hospital_name = response1.data.hospital_name
                    this.PaymentRecevableList.invoice_date = response1.data.invoice_date
                    this.PaymentRecevableList.bill_amount = response1.data.bill_amount
                    this.PaymentRecevableList.received_amount = response1.data.received_amount,
                        this.PaymentRecevableList.invoice_no = response1.data.invoice_no
                    this.PaymentRecevableList.payment_status = response1.data.payment_status
                    this.PaymentRecevableList.payment_id = response1.data.payment_id
                    this.GetPaymentDetail = response1.data?.paymentInfo
                    if (this.PaymentRecevableList.payment_status == 'Paid') {
                        this.disableBtn = true
                    }
                    else {
                        this.disableBtn = false
                    }
                } else {
                    this.viewList = []
                }
                this.getAllPayment()
            }
            this.snackbarText = response.message
            this.snackbar = true
        },


        async getAllPayment() {
            this.listLoading = true
            const service = new PaymentReceivable()
            let response = await service.ViewAllPayment();
            if (response) {
                this.GetLadingBill = response.data
                this.totallist = this.GetLadingBill.length
                this.filter.hospital_name = '',
                    this.filter.payment_status = '',
                    this.filter.date_from = '',
                    this.filter.date_to = ''

            } else {
                this.GetLadingBill = []
                this.totallist = 0
            }
        },


        BillCancel(item) {
            this.DeleteModal = true
            this.thisItemId = item.id
        },

        async PaymentReceivableSearchList() {
            this.listLoading = true
            const data = {
                hospital_name: this.filter.hospital_name,
                payment_status: this.filter.payment_status,
                date_from: this.filter.date_from,
                date_to: this.filter.date_to,
            }
            if (this.filter.hospital_name == '' && this.filter.payment_status == '' && this.filter.date_from == '' && this.filter.date_to == '') {
                this.snackbarText = 'Kindly Enter The Keyword',
                    this.snackbar = true
                return;
            }
            try {
                const response = await api.post(`paymentReceivable/searchPayment`, data, {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                })
                if (response) {
                    console.log(response)
                    this.GetLadingBill = response.data.data
                    this.totallist = this.GetLadingBill.length
                } else {
                    this.GetLadingBill = []
                    this.totallist = 0
                }
                this.listLoading = false
            } catch (e) {
                console.log(e)
            }
        },

        async autoSearchBillOFlading() {
            const tissue_id = this.$route.params.tissue_id
            if (tissue_id) {
                this.listLoading = true
                const data = {
                    // hospital_name: this.filter.hospital_name,
                    // invoice_no: this.filter.invoice_no,
                    tissue_no: tissue_id,
                }
                try {
                    const response = await api.post(`ladingBill/search`, data, {
                        headers: {
                            'Content-type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    if (response) {
                        this.GetLadingBill = response.data.data
                        this.totallist = this.GetLadingBill.length
                    } else {
                        this.GetLadingBill = []
                        this.totallist = 0
                    }
                    this.listLoading = false
                } catch (e) {
                    console.log(e)
                }
            }
            return;
        },


    },


}
</script>

